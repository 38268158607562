<template>
    <div class="hfull">
      <div class="header pt-lg-5">
        <b-container>
          <div class="header-body text-center mb-7">
            <b-row class="justify-content-center">
              <img
                src="../../../public/img/brand/img-logo-color.png"
                alt="klhk"
                class="mb-2"
              />
              <b-col md="12">
                <h2 class="text-black">
                  PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                  MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                  DAN/ATAU KEGIATAN
                </h2>
                <p class="text-lead text-white">
                  Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                  validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                  bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                  Identification).
                </p>
              </b-col>
            </b-row>
            <button class="btn btn-primary" @click="showAnnoncementModal = true">
              LIHAT MEKANISME
            </button>
          </div>
        </b-container>
      </div>
  
      <b-container>
        <b-row>
          <b-col xl="12">
            <div>
              <b-card
                title="Pendaftaran"
                tag="article"
                class="mb-2"
                style="min-height: 210px; overflow: hidden"
              >
                <div style="z-index: 100; position: relative; z-index: 100">
                  <b-button variant="primary" @click="showChangeDataForm"
                    >Perubahan Data</b-button
                  >
                  <b-button variant="secondary" @click="showIsNormalOrWaste"
                    >Pendaftaran Baru</b-button
                  >
                </div>
                <div style="position: relative; z-index: 0">
                  <img
                    src="../../../public/img/brand/klhk.png"
                    alt=""
                    style="
                      position: absolute;
                      bottom: -400px;
                      right: -400px;
                      width: 80%;
                      opacity: 0.5;
                    "
                  />
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
  
      <!-- Table ID Pendaftaran -->
      <b-container class="mt-5 pb-5" v-if="cekStatusForm">
        <b-row class="justify-content-center">
          <b-col md="12">
            <b-card>
              <h3 class="mb-3">Riwayat Pengajuan dan Validasi</h3>
              <div>
                <el-table row-key="_id" header-row-class-name="thead-light" lazy>
                  <el-table-column label="No" width="50" type="index" />
                  <el-table-column
                    label="Daftar Pengajuan"
                    style="padding-left: 10px"
                  >
                    <el-table-column
                      prop="companyName"
                      label="Nama"
                      width="100"
                    />
                    <el-table-column label="Tanggal" width="120">
                      <template v-slot="{ row }">
                        {{ 20 - 10 - 2020 }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="keterangan" label="Keterangan">
                      <template v-slot="{ row }">
                        <div v-html="row.keterangan"></div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="Status Validasi">
                    <el-table-column prop="user_id" label="Nama" width="100" />
                    <el-table-column label="Tanggal" width="120">
                      <template v-slot="{ row }">
                        {{ 20 - 10 - 2020 }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status" width="120" />
                    <!-- <el-table-column label="Column"></el-table-column> -->
                  </el-table-column>
                </el-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
  
      <!-- Pendaftaran Normal -->
      <b-container class="mt-5 pb-5" v-if="showFormRegisterNormally">
        <b-row class="justify-content-center">
          <b-col md="12">
            <b-card>
              <h3 class="my-5 text-center">
                Pendaftaran Baru - Pendaftaran Normal
              </h3>
              <form-wizard @onComplete="submitRegisterNormally">
                <!-- Identifikasi Sumber Pencemar done -->
                <tab-content title="Identifikasi Sumber Pencemar">
                  <div class="p-3">

                    <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Permohonan Koneksi</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        rules="required"
                        name="File Permohonan Koneksi"
                      >
                        <file-input
                          :disabled="uploading"
                          accept="application/pdf"
                          ref="connection_request"
                          @change="uploadFile('umum', 'connection_request')"
                        ></file-input>
                      </base-input>
                      <small class="d-block mt--3 text-justify">
                        Surat permohonan pendaftaran koneksi sparing kepada
                        Direktur Pengendalian Pencemaran Air.
                      </small>
                    </div>
                  </div>





                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Izin Pembuangan Air Limbah ke MediaAir</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Izin Pembuangan Air Limbah ke Media Air"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="waste_water_disposal_permit"
                            @change="uploadFile('teknis', 'waste_water_disposal_permit')"
                          ></file-input>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Pejabat Penerbit Izin</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Pejabat Penerbit Izin"
                          rules="required"
                          v-model="form.teknis.permitPerson"
                        ></base-input>
                        <small class="d-block mt--3 text-justify">
                          Contoh Kepala Dinas Modal dan Pelayanan Terpadu Satu
                          Pintu (DPM-PTSP) Provinsi DKI Jakarta
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label
                        class="col-lg-3 col-form-label form-control-label mt--2"
                        >Nomor Izin Pembuangan Air Limbah (Definitif)</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Nomor Izin Pembuangan Air Limbah (Definitif)"
                          rules="required"
                          v-model="form.teknis.permitNumber"
                        ></base-input>
                        <small class="d-block mt--3 text-justify">
                          Diisi nomor izin pembuangan air limbah, bukan surat
                          pemenuhan komitmen. Contoh : Nomor :
                          503/KEP.B75BFFFC-PTSP/2019.
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Tanggal Izin Terbit</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          class="m-0"
                          rules="required"
                          name="Tanggal Izin Terbit"
                        >
                          <flat-picker
                            name="Tanggal Izin Terbit"
                            rules="required"
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="selects.configs.flatpickr"
                            class="form-control datepicker"
                            v-model="form.teknis.permitDatePublish"
                          ></flat-picker>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Berlaku</label
                      >
                      <div class="col-lg-9">
                        <base-input class="m-0" rules="required" name="Berlaku">
                          <flat-picker
                            name="Berlaku"
                            rules="required"
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="selects.configs.flatpickr"
                            class="form-control datepicker"
                            v-model="form.teknis.permitDateExpire"
                          ></flat-picker>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Sumber Air Limbah</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Sumber Air Limbah"
                          rules="required"
                          v-model="form.teknis.wasteSource"
                        ></base-input>
                        <small class="d-block mt--3 text-justify">
                          Air Limbah proses produksi/air limbah utilitas/air
                          limbah domestik/air limbah pencucian atau pengolahan
                          mineral/air limbah penambangan mineral/air limbah
                          pencucian atau pengolahan batu bara/air limbah
                          penambangan batu bara.
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label
                        for="example-number-input"
                        class="col-lg-3 col-form-label form-control-label"
                        >Jumlah Titik Penaatan</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Jumlah Titik Penaatan"
                          rules="required"
                          type="number"
                          v-model="countTitik"
                        />
                        <small class="d-block mt--3 text-justify"
                          >Jumlah titik penaatan yang diwajibkan didalam
                          izin.</small
                        >
                      </div>
                    </div>
  
                    <div
                      v-for="(titik, index) in form.teknis.spotList"
                      :key="index"
                      class="card border-0"
                      style="background: #ededed"
                    >
                      <div class="card-header px-4" style="background: #ededed">
                        <label class="col-form-label form-control-label"
                          ><b>Nama Titik Penaatan {{ index + 1 }}</b></label
                        >
                      </div>
                      <div class="card-body p-4">
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Titik Penaatan</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Titik Penaatan"
                              rules="required"
                              v-model="titik.spotName"
                            />
                            <small
                              v-if="index === 0"
                              class="d-block mt--3 text-justify"
                            >
                              Nama titik penaatan yang akan didaftarkan untuk
                              SPARING WAJIB sama dengan titik penaatan aktif pada
                              SIMPEL PPA
                            </small>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Lintang</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Lintang"
                              rules="required"
                              type="number"
                              v-model="titik.spotLoc[0]"
                            />
                            <small class="d-block mt--3 text-justify"
                              >Contoh: -7.77751</small
                            >
                            <br />
                            <small
                              v-if="index === 0"
                              class="d-block mt--3 text-justify"
                            >
                              Titik penaatan yang akan didaftarkan untuk SPARING.
                            </small>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Bujur</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Bujur"
                              rules="required"
                              type="number"
                              v-model="titik.spotLoc[1]"
                            />
                            <small class="d-block mt--3 text-justify"
                              >Contoh: 110.37804</small
                            >
                            <br />
                            <small
                              v-if="index === 0"
                              class="d-block mt--3 text-justify"
                            >
                              Titik penaatan yang akan didaftarkan untuk SPARING.
                            </small>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Nama Badan Air Penerima Air Limbah</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="Nama Badan Air Penerima Air Limbah"
                              rules="required"
                              v-model="titik.penerimaAirLimbah"
                            />
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >DAS</label
                          >
                          <div class="col-lg-9">
                            <base-input
                              name="DAS"
                              rules="required"
                              v-model="titik.das"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label
                        class="col-lg-3 col-form-label form-control-label mt--2"
                        >Jenis Teknologi Pengolahan AirLimbah</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Jenis Teknologi Pengolahan Air Limbah"
                          rules="required"
                          v-model="form.teknis.processingTechnique"
                        />
                        <small class="d-block mt--3 text-justify">
                          Disesuaikan dengan Izin pembuangan air limbah atau izin
                          lingkungan/AMDAL/UKL UPL/RKL RPL. Contoh: Aerob,
                          Anaerob, dll.
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Jenis Air Limbah</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Jenis Air Limbah"
                          rules="required"
                          v-model="form.teknis.wasteType"
                        />
                        <small class="d-block mt--3 text-justify">
                          Contoh: air terproduksi, air limbah proses, air limbah
                          lokasi penambangan, dll.
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Debit</label
                      >
                      <div class="col-lg-9 d-flex px-0">
                        <div class="col-9" id="debit">
                          <base-input
                            name="debit"
                            rules="required"
                            v-model="form.teknis.debit"
                          />
                          <small class="d-block mt--3 text-justify"
                            >Debit Maksimum yang wajib di dalam izin</small
                          >
                        </div>
                        <div class="col-3">
                          <base-input name="Satuan debit" rules="required">
                            <el-select v-model="form.teknis.satuanDebit">
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Kapasitas Produksi Sesuai Izin</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Kapasitas Produksi Sesuai Izin"
                          rules="required"
                          type="number"
                          v-model="form.teknis.capacityPermit"
                        />
                        <small class="d-block mt--3 text-justify"
                          >Izin Lingkungan/AMDAL/UKL UPL/RKL RPL</small
                        >
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Kapasitas Produksi Senyatanya</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Kapasitas Produksi Senyatanya"
                          rules="required"
                          type="number"
                          v-model="form.teknis.capacityActual"
                        />
                        <small class="d-block mt--3 text-justify">
                          Pencatatan logbook bulanan selama 1 atau 2 tahun
                          terakhir. Kapasitas produksi rata-rata bulanan.
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Frekuensi Pembuangan Air Limbah</label
                      >
                      <div class="col-lg-9">
                        <base-input name="Per tahun" rules="required">
                          <el-select v-model="form.teknis.frequenceDet">
                            <el-option
                              label="Terus Menerus/Kontinyu"
                              value="Terus Menerus/Kontinyu"
                            ></el-option>
                            <el-option
                              label="Tidak Rutin/Intermiten"
                              value="Tidak Rutin/Intermiten"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
  
                    <div
                      v-if="form.teknis.frequenceDet === 'Terus Menerus/Kontinyu'"
                      class="card bg-white border-0"
                    >
                      <div class="card-header px-4">
                        Detail Frekuensi Pembuangan Air Limbah
                      </div>
                      <div class="card-body p-4">
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per hari</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per hari" rules="required">
                              <el-select v-model="form.frekuensi.jam">
                                <el-option
                                  v-for="item in 24"
                                  :key="item + ' jam'"
                                  :label="item + ' jam'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per minggu</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per minggu" rules="required">
                              <el-select v-model="form.frekuensi.hari">
                                <el-option
                                  v-for="item in 7"
                                  :key="item + ' hari'"
                                  :label="item + ' hari'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per tahun</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per tahun" rules="required">
                              <el-select v-model="form.frekuensi.bulan">
                                <el-option
                                  v-for="item in 12"
                                  :key="item + ' bulan'"
                                  :label="item + ' bulan'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div
                      v-if="form.teknis.frequenceDet === 'Tidak Rutin/Intermiten'"
                      class="card bg-white border-0"
                    >
                      <div class="card-header px-4">
                        Detail Frekuensi Pembuangan Air Limbah
                      </div>
                      <div class="card-body p-4">
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Latar Belakang</label
                          >
                          <div class="col-lg-9">
                            <html-editor
                              v-model="form.frekuensi.latarBelakang"
                            ></html-editor>
                            <small class="d-block mt--3 text-justify">
                              Penjelasan (deskripsi) dilakukan pembuangan air
                              limbah secara intermiten
                            </small>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Detail Engineering IPAL</label
                          >
                          <div class="col-lg-9">
                            <html-editor
                              v-model="form.frekuensi.detEngIPAL"
                            ></html-editor>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Foto IPAL</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Foto IPAL" rules="required">
                              <file-input
                                :disabled="uploading"
                                accept="image/x-png, image/jpeg, application/pdf"
                                ref="ipal_photo"
                                @change="uploadFile('frekuensi', 'ipal_photo')"
                              ></file-input>
                            </base-input>
                            <small class="d-block mt--3 text-justify"
                              >Bangunan IPAL dan outlet.</small
                            >
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per hari</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per hari" rules="required">
                              <el-select v-model="form.frekuensi.jam">
                                <el-option
                                  v-for="item in 24"
                                  :key="item + ' jam'"
                                  :label="item + ' jam'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per minggu</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per minggu" rules="required">
                              <el-select v-model="form.frekuensi.hari">
                                <el-option
                                  v-for="item in 7"
                                  :key="item + ' hari'"
                                  :label="item + ' hari'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Per tahun</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Per tahun" rules="required">
                              <el-select v-model="form.frekuensi.bulan">
                                <el-option
                                  v-for="item in 12"
                                  :key="item + ' bulan'"
                                  :label="item + ' bulan'"
                                  :value="item"
                                ></el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Metode Pengukuran Debit</label
                          >
                          <div class="col-lg-9">
                            <html-editor
                              v-model="form.frekuensi.metUkurDebit"
                            ></html-editor>
                            <small class="d-block mt--3 text-justify"
                              >Metode pengukuran debit yang dilakukan secara
                              manual.</small
                            >
                          </div>
                        </div>
  
                        <div class="form-group row">
                          <label
                            class="col-lg-3 col-form-label form-control-label"
                            >Logbook Pembuangan Air Limbah</label
                          >
                          <div class="col-lg-9">
                            <base-input name="Logbook" rules="required">
                              <file-input
                                :disabled="uploading"
                                accept="application/pdf"
                                ref="waste_water_disposal_logbook"
                                @change="uploadFile('frekuensi', 'waste_water_disposal_logbook')"
                              ></file-input>
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-content>
  
                <!-- Persyaratan Teknis done -->
                <tab-content title="Persyaratan Teknis">
                  <div class="p-3">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Lulus Uji Konektifitas</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Lulus Uji Konektifitas"
                          rules="required"
                        >
                          <el-select v-model="form.validitas.lulusUji">
                            <el-option
                              label="Individu"
                              value="Individu"
                            ></el-option>
                            <el-option
                              label="Penyedia Barang dan Jasa Alat Sparing"
                              value="Penyedia Barang dan Jasa Alat Sparing"
                            ></el-option>
                          </el-select>
                        </base-input>
                        <small class="d-block mt--3 text-justify">
                          Individu dipilih bagi industri yang memasang dan
                          mengoperasikan tanpa bekerja sama dengan penyedia
                          barang/jasa alat sensor dan logger.
                        </small>
                      </div>
                    </div>
                    <div
                      v-if="form.validitas.lulusUji === 'Individu'"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Nomor Surat Lulus Uji Konektivitas</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Brand"
                          rules="required"
                          v-model="form.validitas.noSurat"
                        />
                        <small class="d-block mt--3 text-justify"
                          >Nomor surat Lulus Uji Konektivitas diterbitkan oleh
                          KLHK</small
                        >
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">
                        Surat Keterangan Lulus Uji Konektivitas
                      </label>
                      <div class="col-lg-9">
                        <base-input
                          name="Lulus Uji Konektivitas"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="certificate_connectivity_test"
                            @change="uploadFile('validitas', 'certificate_connectivity_test')"
                          />
                        </base-input>
                        <small class="d-block mt--3 text-justify"
                          >Wajib yang masih berlaku</small
                        >
                      </div>
                    </div>
  
                    <div
                      v-if="
                        form.validitas.lulusUji ===
                        'Penyedia Barang dan Jasa Alat Sparing'
                      "
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Nama Penyedia Barang dan Jasa</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Brand"
                          rules="required"
                          v-model="form.validitas.namaPenyedia"
                        />
                        <small class="d-block mt--3 text-justify"
                          >Surat Keterangan Lulus Uji Konektivitas.</small
                        >
                      </div>
                    </div>
                    <div
                      v-if="
                        form.validitas.lulusUji ===
                        'Penyedia Barang dan Jasa Alat Sparing'
                      "
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Kontrak Kerjasama</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Surat Keterangan Lulus Uji Konektivitas"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="cooperative_contract_vendor"
                            @change="uploadFile('validitas', 'cooperative_contract_vendor')"
                          ></file-input>
                        </base-input>
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Info Alat</label
                      >
                      <div class="col-lg-9">
                        <base-input name="Per tahun" rules="required">
                          <el-select v-model="form.validitas.infoAlat">
                            <el-option label="Baru" value="Baru"></el-option>
                            <el-option label="Lama" value="Lama"></el-option>
                          </el-select>
                        </base-input>
                        <small class="d-block mt--3 text-justify">
                          Alat lama adalah sensor yang sudah terpasang sebelum
                          Permen LH 93/2018.
                        </small>
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Hasil Kalibrasi Saat Awal Pemasangan Alat</label
                      >
                      <div
                        v-if="form.validitas.lulusUji !== null"
                        class="col-lg-9"
                      >
                        <base-input
                          name="Hasil Kalibrasi Saat Awal Pemasangan Alat"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="certificate_calibration_initial"
                            @change="uploadFile('validitas', 'certificate_calibration_initial')"
                          ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify"
                          >Sertifikat hasil uji kalibrasi.</small
                        >
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Hasil Kalibrasi Alat Rutin</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Hasil Kalibrasi Alat Rutin"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="certificate_calibration_rountine"
                            @change="
                              uploadFile('validitas', 'certificate_calibration_rountine')
                            "
                          ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify"
                          >Sertifikat hasil uji kalibrasi.</small
                        >
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label">
                        Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh
                        KLHK
                      </label>
                      <div class="col-lg-9">
                        <base-input
                          name="Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh KLHK"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="certificate_calibration_lab"
                            @change="uploadFile('validitas', 'certificate_calibration_lab')"
                          ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify">
                          Uji kinerja alat wajib dilaksanakan di laboratorium yang
                          ditunjuk oleh KLHK cq. Pusat Standarisasi Lingkungan dan
                          Kehutanan. Laboratorium dimaksud adalah laboratorium
                          yang melaksanakan pengujian kinerja alat pemantauan
                          online air sesuai prosedur dalam petunjuk teknis Uji
                          Kinerja Alat Pemantauan Online untuk Air mengacu kepada
                          SNI ISO 15839:2003.
                        </small>
                        <small
                          v-if="form.validitas.kinerjaFile.upload == 2"
                          class="d-block mt-4 text-justify"
                        >
                          <a
                            :href="`${baseURL}/${form.validitas.kinerjaFile.path}`"
                            target="_blank"
                            class="rounded bg-primary p-2 text-white"
                            >Lihat file</a
                          >
                        </small>
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Surat Dukungan Dari Brand Sensor</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Surat Dukungan Dari Brand Sensor"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="certificate_calibration_brand"
                            @change="uploadFile('surat', 'certificate_calibration_brand')"
                          ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify"
                          >Upload Surat Dukungan Dari Brand Sensor</small
                        >
                      </div>
                    </div>
  
                    <div
                      v-if="form.validitas.lulusUji !== null"
                      class="form-group row"
                    >
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Surat Pernyataan</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Surat Pernyataan dari Vendor Sensor"
                          rules="required"
                        >
                          <file-input
                            :disabled="uploading"
                            accept="application/pdf"
                            ref="statement_letter"
                            @change="uploadFile('surat', 'statement_letter')"
                          ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify">
                          Khusus untuk perusahaan atau vendor yang merakit alat
                          sensor,
                          <b>secara mandiri</b> menyampaikan surat pernyataan.
                        </small>
                      </div>
                    </div>
                  </div>
                </tab-content>
  
                <!-- Data Logger done -->
                <tab-content title="Data Logger">
                  <div class="p-3">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Brand</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Brand Logger"
                          rules="required"
                          v-model="form.logger[0].brand"
                        />
                        <small class="d-block mt--3 text-justify">
                          Jika menggunakan data logger Lokal (Rakitan Lokal),
                          disampaikan spesifikasinya
                        </small>
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Model</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Model"
                          rules="required"
                          v-model="form.logger[0].model"
                        />
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Serial Number</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="Serial Number"
                          rules="required"
                          v-model="form.logger[0].sn"
                        />
                      </div>
                    </div>
  
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >MAC Address</label
                      >
                      <div class="col-lg-9">
                        <base-input
                          name="MAC Address"
                          rules="required"
                          v-model="form.logger[0].mac"
                        />
                        <small class="d-block mt--3 text-justify"
                          >Contoh: OA:0E:AA:8R:77</small
                        >
                      </div>
                    </div>
                  </div>
                </tab-content>
  
                <!-- Sensor -->
                <tab-content title="Sensor">
                  <div class="p-3">
                    <div v-for="(item, index) in form.sensor" :key="item.name">
                      <div class="form-group row">
                        <label
                          class="col-lg-3 col-form-label form-control-label"
                          >{{ item.label }}</label
                        >
                        <div class="col-lg-9">
                          <base-checkbox
                            v-model="form.sensor[index].active"
                            class="mt-2 mb-3"
                          >
                            {{
                              form.sensor[index].active
                                ? "Digunakan"
                                : "Tidak digunakan"
                            }}
                          </base-checkbox>
                          <small class="d-block mt--3 text-justify">
                            Aktifkan checkbox jika sensor digunakan untuk mengisi
                            data sensor
                          </small>
                        </div>
                      </div>
                      <template v-if="form.sensor[index].active">
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                              >Brand</label
                            >
                            <div class="col-12">
                              <base-input
                                rules="required"
                                :name="`brand ${form.sensor[index].name}`"
                                v-model="form.sensor[index].brand"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                              >Probe</label
                            >
                            <div class="col-12">
                              <base-input
                                :name="`Probe ${form.sensor[index].name}`"
                                rules="required"
                                v-model="form.sensor[index].probe"
                              />
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-11 col-form-label form-control-label py-0"
                              >Brosur</label
                            >
                            <div class="col-12">
                              <base-input
                                :name="`Brosur ${form.sensor[index].name}`"
                                rules="required"
                              >
                                <file-input
                                  :disabled="uploading"
                                  accept="application/pdf"
                                  :ref="'brosurFile' + index"
                                  @change="uploadSensorFile(index)"
                                />
                              </base-input>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9">
                            <label
                              class="col-11 col-form-label form-control-label px-0"
                              >Range Pengukuran Sensor</label
                            >
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <base-input
                                      :name="`Range Pengukuran Sensor ${form.sensor[index].name} Minimal`"
                                      rules="required"
                                      type="number"
                                      v-model="form.sensor[index].minMeasure"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Minimum</small
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <base-input
                                      rules="required"
                                      :name="`Range Pengukuran Sensor ${form.sensor[index].name} Maksimal`"
                                      type="number"
                                      v-model="form.sensor[index].maxMeasure"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Maximum</small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9">
                            <label
                              class="col-11 col-form-label form-control-label px-0"
                              >Baku Mutu Air Limbah Sesuai Izin</label
                            >
                            <div v-if="item.name === 'pH'" class="row">
                              <div class="col-6">
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <base-input
                                      :name="`Baku Mutu ${form.sensor[index].name} Minimal`"
                                      rules="required"
                                      type="number"
                                      v-model="form.sensor[index].min"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Minimum</small
                                    >
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <base-input
                                      type="number"
                                      :name="`Baku Mutu ${form.sensor[index].name} Maksimal`"
                                      rules="required"
                                      v-model="form.sensor[index].max"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Maximum</small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="item.name === 'Debit'" class="row">
                              <div class="col-12">
                                <div class="form-group row">
                                  <div class="col-lg-9">
                                    <base-input
                                      type="number"
                                      rules="required"
                                      name="Debit maksimum"
                                      v-model="form.sensor[index].max"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Maximum</small
                                    >
                                  </div>
                                  <div class="col-3">
                                    <base-input
                                      name="Satuan sensor debit"
                                      rules="required"
                                    >
                                      <el-select
                                        v-model="form.sensor[index].satuanDebit"
                                      >
                                        <el-option
                                          v-for="(unit, index) in debitUnit"
                                          :key="index"
                                          :label="unit"
                                          :value="unit"
                                        />
                                      </el-select>
                                    </base-input>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else class="row">
                              <div class="col-12">
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <base-input
                                      type="number"
                                      rules="required"
                                      :name="`${form.sensor[index].name} maksimum`"
                                      v-model="form.sensor[index].max"
                                    />
                                    <small class="d-block mt--4 text-justify"
                                      >Maximum</small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                              >Metode Pengukuran</label
                            >
                            <div class="col-12">
                              <base-input
                                rules="required"
                                :name="`Metode Pengukuran ${form.sensor[index].name}`"
                                v-model="form.sensor[index].metodePengukuran"
                              />
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                            >
                              Jadwal Kalibrasi
                            </label>
                            <div class="col-12">
                              <base-input
                                rules="required"
                                :name="`Jadwal Kalibrasi ${form.sensor[index].name}`"
                              >
                                <el-select
                                  v-model="form.sensor[index].jadwalKalibrasi"
                                >
                                  <el-option
                                    v-for="bulan in 12"
                                    :key="bulan"
                                    :label="`${bulan} Bulan`"
                                    :value="`${bulan} Bulan`"
                                  />
                                </el-select>
                              </base-input>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                              >Tanggal Kalibrasi</label
                            >
                            <div class="col-12">
                              <base-input
                                class="m-0"
                                rules="required"
                                :name="`Tanggal Kalibrasi ${form.sensor[index].name}`"
                              >
                                <flat-picker
                                  :config="selects.configs.flatpickr"
                                  class="form-control datepicker"
                                  v-model="form.sensor[index].tanggalKalibrasi"
                                />
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-3"></div>
                          <div class="form-group col-lg-9 p-0">
                            <label
                              class="col-12 col-form-label form-control-label py-0"
                              >Metode Kalibrasi</label
                            >
                            <div class="col-12">
                              <base-input
                                rules="required"
                                :name="`Metode Kalibrasi ${form.sensor[index].name}`"
                                v-model="form.sensor[index].metodeKalibrasi"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <base-input
                      :rules="{ required: { allowFalse: false } }"
                      name="Sensor"
                    >
                      <base-checkbox v-model="form.sensorDone"
                        >Data sensor sudah benar</base-checkbox
                      >
                    </base-input>
                  </div>
                </tab-content>
              </form-wizard>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
  
      <!-- Pendaftaran khusus yang memanfaatkan air limbah -->
      <b-container class="mt-5" v-if="showFormRegisterWaste">
        <b-row class="justify-content-center">
          <b-col>
            <b-card>
              <h3 class="my-5 text-center">
                Pendaftaran Baru - Pendaftaran khusus yang memanfaatkan air limbah
              </h3>
              <form-wizard @onComplete="submitRegisterWaste">
                <tab-content title="Air Limbah">
                  <div class="P-3">
                    <div class="mb-5">
                      Khusus Industri Yang Menfaatkan Air Limbah 100% Ke Proses
                      Produksi
                    </div>
                    <div>

                        <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Permohonan Koneksi</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        rules="required"
                        name="File Permohonan Koneksi"
                      >
                        <file-input
                          :disabled="uploading"
                          accept="application/pdf"
                          ref="connection_request"
                          @change="uploadFile('umum', 'connection_request')"
                        ></file-input>
                      </base-input>
                      <small class="d-block mt--3 text-justify">
                        Surat permohonan pendaftaran koneksi sparing kepada
                        Direktur Pengendalian Pencemaran Air.
                      </small>
                    </div>
                  </div>




                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Penggunaan Air</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Penggunaan Air"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitPenggunaan.value"
                          />
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Penggunaan Air"
                            rules="required"
                          >
                            <el-select
                              v-model="form.prosesProduksi.debitPenggunaan.satuan"
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan Debit Penggunaan Air"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan) "
                            rules="required"
                            v-model="
                              form.prosesProduksi.debitPenggunaan.keterangan
                            "
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Air Limbah Total</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Air Limbah Total"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitLimbahTotal.value"
                          />
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Air Limbah Total"
                            rules="required"
                          >
                            <el-select
                              v-model="
                                form.prosesProduksi.debitLimbahTotal.satuan
                              "
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan Debit Air Limbah Total"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan)"
                            rules="required"
                            v-model="
                              form.prosesProduksi.debitLimbahTotal.keterangan
                            "
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Air Reuse</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Air Reuse"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitReuse.value"
                          />
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Air Reuse"
                            rules="required"
                          >
                            <el-select
                              v-model="form.prosesProduksi.debitReuse.satuan"
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan Debit Air Reuse"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan)"
                            rules="required"
                            v-model="form.prosesProduksi.debitReuse.keterangan"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Air Recycle</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Air Recycle"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitRecycle.value"
                          ></base-input>
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Air Recycle"
                            rules="required"
                          >
                            <el-select
                              v-model="form.prosesProduksi.debitRecycle.satuan"
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan Debit Air Recycle"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan)"
                            rules="required"
                            v-model="form.prosesProduksi.debitRecycle.keterangan"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Air Recovery</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Air Recovery"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitRecovery.value"
                          />
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Air Recovery"
                            rules="required"
                          >
                            <el-select
                              v-model="form.prosesProduksi.debitRecovery.satuan"
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan)"
                            rules="required"
                            v-model="form.prosesProduksi.debitRecovery.keterangan"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label"
                          >Debit Air Limbah Diolah IPAL</label
                        >
                        <div class="col-lg-2">
                          <base-input
                            name="Debit Air Limbah Diolah IPAL"
                            placeholder="Masukan nilai debit"
                            rules="required"
                            v-model="form.prosesProduksi.debitOlahIPAL.value"
                          />
                        </div>
                        <div class="col-3">
                          <base-input
                            name="Satuan Debit Air Limbah Diolah IPAL"
                            rules="required"
                          >
                            <el-select
                              v-model="form.prosesProduksi.debitOlahIPAL.satuan"
                            >
                              <el-option
                                v-for="(unit, index) in debitUnit"
                                :key="index"
                                :label="unit"
                                :value="unit"
                              />
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg">
                          <base-input
                            name="Keterangan Debit Air Limbah Diolah IPAL"
                            placeholder="Keterangan (Proses dan Teknologi yang digunakan)"
                            rules="required"
                            v-model="form.prosesProduksi.debitOlahIPAL.keterangan"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-2 col-form-label form-control-label">
                          Unggah Neraca Air Dan Unggah Air Limbah
                        </label>
                        <div class="col-lg-10">
                          <base-input
                            rules="required"
                            name="File Neraca Air Dan Unggah Air Limbah"
                          >
                            <file-input
                              :disabled="uploading"
                              accept="application/pdf"
                              ref="water_balance_and_wastewater"
                              @change="
                                uploadFile(
                                  'prosesProduksi',
                                  'water_balance_and_wastewater'
                                )
                              "
                            ></file-input>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
  
      <Modal
        :show="showAnnoncementModal"
        modalContentClasses="announcement-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        @close="showAnnoncementModal = false"
        size="xl"
      >
        <template v-slot:header>
          <div class="mdl-header d-flex">
            <img
              src="../../../public/img/brand/img-logo-color.png"
              alt="klhk"
              class="mb-2"
            />
            <h3 class="ml-3">
              Kementerian <br />
              Lingkungan Hidup dan Kehutanan <br />
              Republik Indonesia
            </h3>
          </div>
        </template>
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contents">
            <div class="p-4" style="overflow-y: scroll; height: 450px">
              <h2 class="text-justify"></h2>
              <h2 class="text-justify">
                PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                DAN/ATAU KEGIATAN
              </h2>
              <p class="mb-2 text-justify">
                Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                Identification).
                <br />Mekanisme pendaftaran yaitu:
              </p>
              <ol>
                <li>
                  Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                  kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                  & jasa alat sensor pemantauan online.
                </li>
                <li>
                  Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                  pendaftaran dan mengisi semua form serta bukti.
                </li>
                <li>
                  Pengisian form pendaftaran dan bukti yaitu:
                  <ol type="a">
                    <li>Pengisian umum</li>
                    <li>
                      Pengisian dan penyampaian bukti persyaratan identifikasi
                      sumber pencemar
                    </li>
                    <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                  </ol>
                </li>
                <li>
                  Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                  pendaftaran paling lambat dikirim 14 hari kerja setelah
                  dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
                </li>
                <li>
                  USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                  TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                  didaftarkan pada form pendaftaran melalui email
                  sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                  dinyatakan valid oleh Admin SPARING KLHK.
                </li>
                <li>
                  Bukti dan data dinyatakan valid melalui bukti persyaratan
                  identifikasi sumber pencemar dan persyaratan teknis SPARING
                  sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                  P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                  Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                  Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                  P.80/MENLHK/SETJEN/KUM.1/10/2019.
                </li>
                <li>
                  Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                  maka Admin SPARING KLHK akan menyampaikan melalui email yang
                  terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                  melengkapi sesuai dengan hasil evaluasi.
                </li>
                <li>
                  Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                  sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                  SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
                </li>
              </ol>
  
              <br />
              <span>
                Tim SPARING<br />
                Direktorat Pengendalian Pencemaran air<br />
                JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
                Gedung B lantai 5<br />
                Email: sparing.menlhk@gmail.com<br />
                Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
                pelayanan hari kerja 08.00-15.00 WIB)<br />
              </span>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="row">
            <div class="col text-right">
              <base-button
                class="bg-primary border-0"
                @click="showAnnoncementModal = false"
              >
                Tutup
              </base-button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </template>
  <script>
  import API from "@/api/base_url.js";
  import FileInput from "@/components/Inputs/FileInput";
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  
  export default {
    name: "pendaftaranSparing",
    components: {
      FileInput,
      HtmlEditor,
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    mounted() {
      this.getSelectFormValue();
    },
    data() {
      return {
        selects: {
          configs: {
            flatpickr: {
              allowInput: true,
              altFormat: "d/m/Y",
              altInput: false,
              dateFormat: "d/m/Y",
              mode: "single",
            },
          },
          options: {
            role: [
              {
                label: "Pusat",
                value: "admin",
              },
              {
                label: "Provinsi",
                value: "prov",
              },
              {
                label: "Kab/Kot",
                value: "kabkot",
              },
              {
                label: "Industri",
                value: "comp",
              },
            ],
            type: [
              { name: "Semua", value: "" },
              { name: "Industri Rayon", value: "Industri Rayon" },
              { name: "Industri Pulp/Kertas", value: "Industri Pulp/Kertas" },
              {
                name: "Industri Petrokimia Hulu",
                value: "Industri Petrokimia Hulu",
              },
              {
                name: "Industri Oleokimia Dasar",
                value: "Industri Oleokimia Dasar",
              },
              { name: "Industri Minyak Sawit", value: "Industri Minyak Sawit" },
              { name: "Pengolahan Minyak Bumi", value: "Pengolahan Minyak Bumi" },
              {
                name: "Eksplorasi dan Produksi Migas",
                value: "Eksplorasi dan Produksi Migas",
              },
              {
                name: "Pertambangan Emas dan Tembaga",
                value: "Pertambangan Emas dan Tembaga",
              },
              { name: "Pertambangan Batu Bara", value: "Pertambangan Batu Bara" },
              { name: "Industri Tekstil", value: "Industri Tekstil" },
              { name: "Pertambangan Nikel", value: "Pertambangan Nikel" },
              { name: "Kawasan Industri", value: "Kawasan Industri" },
            ],
            prov: [],
            city: [],
            comp: [
              {
                label: "Semua",
                value: 0,
              },
              {
                label: "Alpha",
                mail: "alpha",
                value: 1,
              },
              {
                label: "Bravo",
                mail: "bravo",
                value: 2,
              },
              {
                label: "Charlie",
                mail: "charlie",
                value: 3,
              },
              {
                label: "Delta",
                mail: "delta",
                value: 4,
              },
            ],
            emsg: [
              "Data sensor pH melebihi ambang batas!",
              "Data sensor COD melebihi ambang batas!",
              "Data sensor TSS melebihi ambang batas!",
              "Data sensor NH3N melebihi ambang batas!",
              "Data sensor debit melebihi ambang batas!",
            ],
            parameter: [
              {
                label: "pH",
                value: "ph",
              },
              {
                label: "COD",
                value: "cod",
              },
              {
                label: "TSS",
                value: "tss",
              },
              {
                label: "NH3N",
                value: "nh3n",
              },
              {
                label: "Debit",
                value: "debit",
              },
            ],
          },
          type: null,
          prov: null,
          city: null,
          comp: null,
          time: null,
        },
        provideSelect: {
          provinsi: [],
          kabupatenKota: [],
          daftarPerusahaan: [],
          tmp: [],
          uploadChoosen: null,
        },
        showFormRegisterNormally: false,
        showFormRegisterWaste: false,
        cekStatusForm: false,
        isNormalOrWaste: false,
        showForm: false,
        showAnnoncementModal: false,
        form: {
          akun: {
            personName: null,
            personPhone: null,
            personMail: null,
            personMailID: null,
            personPassword: null,
            personConfirmPassword: null,
          },
          umum: {
            compName: null,
            compNameID: null,
            compType: null,
            compAddress: null,
            compStreet: null,
            compCity: null,
            compProvince: null,
            compPhone: null,
            compMail: null,
            nameSIUP: null,
            ifTambang: {
              status: null,
              jenKegiatan: null,
              kondisiInternet: "",
            },
            rencanaFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            siupFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            compPermohonanKoneksi: {
              path: "",
              upload: 0,
              progress: 0,
            },
            updatedAt: 0,
            createdAt: null,
          },
          prosesProduksi: {
            debitPenggunaan: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitLimbahTotal: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitReuse: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecycle: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecovery: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitOlahIPAL: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            fileNeracaAirLimbah: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
          teknis: {
            permitFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            permitPerson: null,
            permitNumber: null,
            permitDatePublish: new Date(),
            permitDateExpire: new Date(),
            wasteSource: null,
            spotCount: null,
            spotList: [
              {
                spotName: "",
                spotLoc: [null, null],
                penerimaAirLimbah: "",
                das: "",
              },
            ],
            processingTechnique: null,
            wasteType: null,
            debit: null,
            satuanDebit: null,
            capacityPermit: null,
            capacityActual: null,
            frequenceDet: "",
          },
          validitas: {
            infoAlat: null,
            lulusUji: null,
            namaPenyedia: null,
            noSurat: null,
            jadwalKalibrasi: null,
            kontrak: {
              path: "",
              upload: 0,
              progress: 0,
            },
            kinerjaFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            konektifitasFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            kalibrasiFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            kalibrasiRutinFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            validitasFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
          frekuensi: {
            latarBelakang: "",
            detEngIPAL: "",
            fotoIPAL: {
              path: "",
              upload: 0,
              progress: 0,
            },
            jam: null,
            hari: null,
            bulan: null,
            metUkurDebit: "",
            logBook: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
          logger: [
            {
              brand: null,
              model: null,
              sn: null,
              mac: null,
            },
          ],
          sensor: [
            {
              name: "pH",
              label: "pH",
              active: false,
              brand: null,
              probe: null,
              brosurFile: {
                path: "",
                upload: 0,
                progress: 0,
              },
              min: null,
              max: null,
              minMeasure: null,
              maxMeasure: null,
              metodePengukuran: null,
              jadwalKalibrasi: null,
              tanggalKalibrasi: new Date(),
              metodeKalibrasi: null,
            },
            {
              name: "COD",
              label: "COD",
              active: false,
              brand: null,
              probe: null,
              brosurFile: {
                path: "",
                upload: 0,
                progress: 0,
              },
              min: null,
              max: null,
              minMeasure: null,
              maxMeasure: null,
              metodePengukuran: null,
              jadwalKalibrasi: null,
              tanggalKalibrasi: new Date(),
              metodeKalibrasi: null,
            },
            {
              name: "TSS",
              label: "TSS",
              active: false,
              brand: null,
              probe: null,
              brosurFile: {
                path: "",
                upload: 0,
                progress: 0,
              },
              min: null,
              max: null,
              minMeasure: null,
              maxMeasure: null,
              metodePengukuran: null,
              jadwalKalibrasi: null,
              tanggalKalibrasi: new Date(),
              metodeKalibrasi: null,
            },
            {
              name: "NH3N",
              label: "NH3N",
              active: false,
              brand: null,
              probe: null,
              brosurFile: {
                path: "",
                upload: 0,
                progress: 0,
              },
              min: null,
              max: null,
              minMeasure: null,
              maxMeasure: null,
              metodePengukuran: null,
              jadwalKalibrasi: null,
              tanggalKalibrasi: new Date(),
              metodeKalibrasi: null,
            },
            {
              name: "Debit",
              label: "Debit",
              active: false,
              brand: null,
              probe: null,
              brosurFile: {
                path: "",
                upload: 0,
                progress: 0,
              },
              min: null,
              max: null,
              minMeasure: null,
              maxMeasure: null,
              metodePengukuran: null,
              jadwalKalibrasi: null,
              tanggalKalibrasi: new Date(),
              metodeKalibrasi: null,
            },
          ],
          sensorDone: false,
          surat: {
            dukunganBrand: {
              path: "",
              upload: 0,
              progress: 0,
            },
            pernyataanVendor: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
        },
        isShowModalForceUpdate: false,
        showNoteModal: false,
        updateNote: "",
        forceUpdateData: null,
        edit: false,
        editID: "",
        isEditData: 0,
        isSectionPendaftaran: 0,
        uploading: false,
        isDataCorrect: true,
        res: { comp: null, user: null, logger: null },
        temptSelect: null,
        debitUnit: null,
        countTitik: 1,
        formData: new FormData(),
      };
    },
    methods: {
      async showChangeDataForm() {
        this.cekStatusForm = false;
        const { value: formValues } = await Swal.fire({
          title: "Perubahan Data",
          confirmButtonColor: "#29664A",
          html: '<input id="swal-input1" class="swal2-input" placeholder="Masukan ID Pendaftaran Anda">',
          focusConfirm: false,
          preConfirm: () => {
            return [document.getElementById("swal-input1").value];
          },
        });
  
        if (formValues) {
          Swal.fire(JSON.stringify(formValues));
        }
      },
      showIsNormalOrWaste() {
        this.cekStatusForm = false;
        // this.isNormalOrWaste = true
        Swal.fire({
          title: "Pilih tipe pendaftaran",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Pendaftaran Normal",
          denyButtonText: `Pendaftaran khusus yang memanfaatkan air limbah`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire("Pendaftaran Normal", "", "success");
            this.showFormRegisterWaste = false;
            this.showFormRegisterNormally = true;
          } else if (result.isDenied) {
            Swal.fire("Pendaftaran khusus air limbah", "", "success");
            this.showFormRegisterNormally = false;
            this.showFormRegisterWaste = true;
          }
        });
      },
      showStatusForm() {
        this.isNormalOrWaste = false;
        this.cekStatusForm = true;
        this.showFormRegisterWaste = false;
        this.showFormRegisterNormally = false;
      },
      submitRegisterNormally() {
        console.log("register normally");
        this.showFormRegisterNormally = false;
  
        let dataTitikPenataan = [];
        for (let i = 0; i < this.form.teknis.spotList.length; i++) {
          console.log(this.form.teknis.spotList[i]);
          dataTitikPenataan.push({
            name: this.form.teknis.spotList[i].spotName,
            latitude: this.form.teknis.spotList[i].spotLoc[0],
            longitude: this.form.teknis.spotList[i].spotLoc[1],
            waste_water_receiving_agency:
              this.form.teknis.spotList[i].penerimaAirLimbah,
            das: this.form.teknis.spotList[i].das,
          });
        }
  
        let payload = {
          user: {
            name: this.form.akun.personName,
            phone: this.form.akun.personPhone,
            email: this.form.akun.personMail,
            password: this.form.akun.personPassword,
          },
          company: {
            name: this.form.umum.compName,
            company_type_id: this.form.umum.compType,
            company_type_data: {
              mine_status: this.form.umum.ifTambang.status,
              type_activity: this.form.umum.ifTambang.jenKegiatan,
              network_conditions: this.form.umum.ifTambang.kondisiInternet,
            },
            siup_number:"012645120",
            siup_on_behalf: this.form.umum.nameSIUP,
            province_id: this.form.umum.compProvince,
            regency_id: this.form.umum.compCity,
            address: this.form.umum.compAddress,
            street: this.form.umum.compStreet,
            phone: this.form.umum.compPhone,
            email: this.form.umum.compMail,
          },
          polluted_sources: {
            permit_issuance_officer: this.form.teknis.permitPerson,
            waste_water_disposal_permit_number: this.form.teknis.permitNumber,
            issue_permit_date: this.form.teknis.permitDatePublish,
            expired_permit_date: this.form.teknis.permitDateExpire,
            waste_water_sources: this.form.teknis.wasteSource,
            type_waste_water_treatment_technology:
              this.form.teknis.processingTechnique,
            type_waste_water: this.form.teknis.wasteType,
            debit_value: this.form.teknis.debit,
            debit_unit: this.form.teknis.satuanDebit,
            permitted_production_capacity: this.form.teknis.capacityPermit,
            actual_production_capacity: this.form.teknis.capacityActual,
            number_compliance_point_num: this.form.teknis.spotList.length,
            number_compliance_point_data: dataTitikPenataan,
            frequency_wastewater_disposal: {
              background: this.form.frekuensi.latarBelakang,
              detail_engineering_ipal: this.form.frekuensi.detEngIPAL,
              day: this.form.frekuensi.jam,
              month: this.form.frekuensi.hari,
              year: this.form.frekuensi.bulan,
              measurement_method: this.form.frekuensi.metUkurDebit,
            },
            data_logger: [
              {
                brand: this.form.logger[0].brand,
                model: this.form.logger[0].model,
                serial_number: this.form.logger[0].sn,
                mac_address: this.form.logger[0].mac,
                sensor: this.form.sensor,
              },
            ],
          },
          technical_requirements: {
            connectivity_test_type: this.form.validitas.lulusUji,
            connectivity_test_vendor: this.form.validitas.namaPenyedia,
            connectivity_test_number: this.form.validitas.noSurat,
            connectivity_test_tool_info: this.form.validitas.infoAlat,
          },
          waste_use: [
            {
              name: "Debit Air",
              value: "12452",
              unit: "m3/h",
              information: "Sata",
            },
          ],
        };
  
        if (
          this.form.akun.personPassword == this.form.akun.personConfirmPassword
        ) {
  
  
          API.post("web/front/registration-data", payload)
            .then(({data}) => {
              this.uploadFile("sendNow", "sendNow");
              Swal.fire('Pendaftaran Berhasil', 'Data Berhasil didaftarkan', 'success')
              this.resetPayload()
              dataTitikPenataan = []
            })
            .catch((err) => {
              console.log(err);
              Swal.fire('Pendaftaran khusus air limbah', err, 'error')
            })
        } else {
          Swal.fire(
            "Password bermasalah",
            "Maaf, Password anda tidak sesuai dengan konfirmasi password",
            "error"
          );
        }
      },
      submitRegisterWaste() {
        console.log("register waste");
        this.showFormRegisterWaste = false;
      },
      submit() {
        console.log("oke");
      },
      close() {
        this.$router.push("/Home");
      },
      resetPayload() {
        console.log("=========Akun");
        this.form.akun.personName = ""
        this.form.akun.personPhone = ""
        this.form.akun.personMail = ""
        this.form.akun.personMailID = ""
        this.form.akun.personPassword = ""
        this.form.akun.personConfirmPassword = ""
        console.log("=========Umum")
        this.form.umum.compName = ""
        this.form.umum.compNameID = ""
        this.form.umum.compType = ""
        this.form.umum.compAddress = ""
        this.form.umum.compStreet = ""
        this.form.umum.compCity = ""
        this.form.umum.compProvince = ""
        this.form.umum.compPhone = ""
        this.form.umum.compMail = ""
        this.form.umum.nameSIUP = ""
        this.form.umum.ifTambang.status = ""
        this.form.umum.ifTambang.jenKegiatan = ""
        this.form.umum.ifTambang.kondisiInternet = ""
        console.log("==================Identifikasi Sumber Pencemaran")
        this.form.teknis.permitPerson = ""
        this.form.teknis.permitNumber = ""
        this.form.teknis.permitDatePublish = ""
        this.form.teknis.permitDateExpire = ""
        this.form.teknis.wasteSource = ""
        this.form.teknis.spotCount = ""
        this.form.teknis.spotList = ""
        this.form.teknis.processingTechnique = ""
        this.form.teknis.wasteType = ""
        this.form.teknis.debit = ""
        this.form.teknis.satuanDebit = ""
        this.form.teknis.capacityPermit = ""
        this.form.teknis.capacityActual = ""
        this.form.teknis.frequenceDet = ""
        this.form.frekuensi.jam = ""
        this.form.frekuensi.hari = ""
        this.form.frekuensi.bulan = ""
        this.form.frekuensi.metUkurDebit = ""
        this.form.frekuensi.latarBelakang = ""
        this.form.frekuensi.detEngIPAL = ""
        console.log("==================Persyaratan Teknis")
        this.form.validitas.infoAlat = ""
        this.form.validitas.lulusUji = ""
        this.form.validitas.namaPenyedia = ""
        this.form.validitas.noSurat = ""
        this.form.validitas.jadwalKalibrasi = ""
        console.log("==================Form Logger")
        this.form.logger[0] = ""
        console.log("==================Sensor")
        this.form.sensor = ""
      },
      getSelectFormValue() {
        API.get("region/provinces")
          .then(({ data: content }) => {
            this.provideSelect.provinsi = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getCityDepentOnProvince(data) {
        this.provideSelect.kabupatenKota = [];
        API.get(`region/regencies/province/${data}`)
          .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      uploadFile(category, field) {
        if (field == 'sendNow') {
          API.post("web/front/registration-file", this.formData)
            .then(({ data }) => {
              console.log(data);
              this.formData = new FormData()
              // alert sukses
            })
            .catch((err) => {
              console.log(err);
              // alert gagal
            });
        } else {
          this.formData.append(field, this.$refs[field].files[0]);
        }
  
        //1 formData.append('siup_number', this.$refs[field].files[0]);
        //2 formData.append('waste_water_disposal_permit', this.$refs[field].files[0]);
        //3 formData.append('ipal_photo', this.$refs[field].files[0]);
        //4 formData.append('waste_water_disposal_logbook', this.$refs[field].files[0]);
        //5 formData.append('certificate_connectivity_test', this.$refs[field].files[0]);
        //6 formData.append('certificate_calibration_initial', this.$refs[field].files[0]);
        //7 formData.append('certificate_calibration_rountine', this.$refs[field].files[0]);
        //8 formData.append('certificate_calibration_lab', this.$refs[field].files[0]);
        //9 formData.append('certificate_calibration_brand', this.$refs[field].files[0]);
        //10 formData.append('statement_letter', this.$refs[field].files[0]);
        //11 formData.append('cooperative_contract_vendor', this.$refs[field].files[0]);
        //12 formData.append('brochure_ph', this.$refs[field].files[0]);
        //13 formData.append('brochure_cod', this.$refs[field].files[0]);
        //14 formData.append('brochure_tss', this.$refs[field].files[0]);
        //15 formData.append('brochure_nh3n', this.$refs[field].files[0]);
        //16 formData.append('brochure_debit', this.$refs[field].files[0]);
        //17 formData.append('compliance_point_determination plan', this.$refs[field].files[0]);
      },
    },
    watch: {
      countTitik() {
        if (this.countTitik < 1) {
          return 0;
        }
        this.form.teknis.spotCount = this.countTitik;
        let array = [];
        for (let i = 0; i < this.countTitik; i++) {
          array.push({
            spotName: "",
            spotLoc: [null, null],
            penerimaAirLimbah: "",
            das: "",
          });
        }
        this.form.teknis.spotList = array;
        return array.length;
      },
    },
    computed: {
      frequencyText() {
        if (this.form.teknis.isContinue) return "Terus Menerus / Kontinyu";
        else return "Tidak Rutin / Intermiten";
      },
      sensorStatus(index) {
        if (this.form.sensor[index]) return "Digunakan";
        else return "Tidak digunakan";
      },
    },
  };
  </script>
  <style>
  .step-progress .bar {
    background-color: #1b674c !important;
  }
  
  .step-button {
    display: inline-block;
    font-weight: 600;
    color: #1b674c;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  
  .step-button-next {
    background-color: #1b674c !important;
    color: #fff;
  }
  
  .step-pills .step-item.active {
    border: 1px solid #1b674c !important;
  }
  
  .step-pills .step-item {
    margin-right: 5px;
  }
  
  .step-item {
    position: relative;
    width: 200px;
    height: 100px;
  }
  
  .tabLabel {
    position: absolute;
    bottom: 10px;
    left: 10px;
    line-height: 17px;
  }
  </style>
  